<template>
  <div class="read-page">
    <div class="read-bg">
      <div class="banner-head">
        <h1>经典名著阅读力</h1>
        <p class="banner-tip">系统规划提升7大阅读能力</p>
        <div class="banner-list">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/white-yes-icon.png">
          <p>8大经典名著讲解</p>
        </div>
        <div class="banner-list">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/white-yes-icon.png">
          <p>名著诵读AI智能评测提语感</p>
        </div>
        <button class="read-buy" @click="openMask(1)">立即报名</button>
      </div>
      <img
        src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/read-icon.png"
        alt="经典名著阅读力"
        class="read-icon"
      >
    </div>
    <div class="read-header">
      课程
      <span>介绍</span>
    </div>
    <div class="read-brief">
      河小象经典名著阅读力提升课立足于新课标统一推荐的必读课外书，
      每个学年段各挑选8本最适合综合阅读力养成的经典名著，由资深儿童图书编辑和教师团队倾力打造。
      多角度、系统式点线面相结合，阶梯式提升孩子阅读综合能力。
    </div>
    <div class="brief-list">
      <div class="brief-box">
        <div class="brief-left">
          <p class="brief-header">21天养成阅读习惯？并不够！</p>
          <p
            class="brief-content"
          >理论上说21天可以帮助人们养成一个习惯，但是对于一个需要长期的学习——阅读而言，更重要的是习惯养成后对7大阅读能力的提升和保持 。 河小象以世界经典名著为教材依托，围绕7大阅读能力的特性，定制了经典名著阅读力提升课课程！</p>
        </div>
        <img
          class="brief-right"
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/read1.png"
        >
      </div>
      <div class="brief-box">
        <img
          class="brief-right img-second"
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/read2.png"
        >
        <div class="brief-left">
          <p class="brief-header">课程效果</p>
          <p class="brief-content">
            分级阅读，一、二年级累计50+万阅读量，三、四年级累计75万+
            增长4倍识字量和词汇量
            涉猎以语文学科为主，数学、生物、地理、历史等10个学科的知识
            阶梯式提升7大阅读能力，养成每天主动阅读的习惯，养成专注力，收获自信出色表达力
          </p>
        </div>
      </div>
      <div class="brief-box">
        <div class="brief-left">
          <p class="brief-header">AI智能诵读打分</p>
          <p class="brief-content">我们从完整度、声韵母、流利度、声调，四个方面检测对孩子诵读进行评分，让孩子更有目的性的诵读训练。</p>
        </div>
        <img
          class="brief-right"
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/read3.png"
        >
      </div>
    </div>
    <div class="arrange">
      <div class="noLine-header">
        课程
        <span>安排</span>
      </div>
      <courseArrage></courseArrage>
    </div>
    <div class="spcial">
      <div class="noLine-header">
        课程
        <span>特色</span>
      </div>
      <div class="spcial-list">
        <div class="spcial-div">
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/spcial1.png"
            class="spcial-img"
          >
          <p class="spcial-title">名著导读</p>
          <p class="spcial-tip">生动形象的故事情景再现，让孩子记住书中每一刻精彩瞬间</p>
        </div>
        <div class="spcial-div">
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/spcial2.png"
            class="spcial-img"
          >
          <p class="spcial-title">生词提炼</p>
          <p class="spcial-tip">提高孩子语文字词认知能力，养成好词好句积累习惯</p>
        </div>
        <div class="spcial-div">
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/spcial3.png"
            class="spcial-img"
          >
          <p class="spcial-title">写作技巧点拨</p>
          <p class="spcial-tip">分析特色句子，讲解写作技巧，让孩子在不知不觉中练成写作达人</p>
        </div>
        <div class="spcial-div">
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/spcial4.png"
            class="spcial-img"
          >
          <p class="spcial-title">名家介绍</p>
          <p class="spcial-tip">趣味图示配合简短知识点拨，让孩子知其书更能知其作者</p>
        </div>
        <div class="spcial-div">
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/spcial5.png"
            class="spcial-img"
          >
          <p class="spcial-title">知识拓展</p>
          <p class="spcial-tip">涉及涉及数学、英语、美术、音乐、生物、地理、历史、社会、天文等10个学科</p>
        </div>
        <div class="spcial-div">
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/spcial6.png"
            class="spcial-img"
          >
          <p class="spcial-title">课外拓展</p>
          <p class="spcial-tip">生活常识，让阅读贴近生活，让孩子学会阅读、学会生活</p>
        </div>
        <div class="spcial-div">
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/spcial7.png"
            class="spcial-img"
          >
          <p class="spcial-title">文学小故事</p>
          <p class="spcial-tip">培养孩子独立思考的能力，引导孩子感悟人生道理</p>
        </div>
        <div class="spcial-div">
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/spcial8.png"
            class="spcial-img"
          >
          <p class="spcial-title">配套教材</p>
          <p class="spcial-tip">童书编辑名师团队精心编写配套教材，让孩子不仅有知识输入，更有情感产出</p>
        </div>
        <div class="spcial-div">
          <img
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/spcial9.png"
            class="spcial-img"
          >
          <p class="spcial-title">阅读章节建议</p>
          <p class="spcial-tip">根据孩子们平均阅读量量身定制。更有特色作业和家长互动环节，做真正的亲子阅读</p>
        </div>
      </div>
    </div>
    <!-- 上课流程 -->
    <div class="class-process">
      <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/read-process.png">
    </div>
    <!-- 我们承诺 -->
    <div class="class-promise">
      <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/we-promise.png">
    </div>
    <!-- 家长反馈 -->
    <feedBack 
      postImg="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/fb-read.png" 
      :list="feedBack" rgba="color:#8558C9" title="true">
    </feedBack>
    <quickTool :downloadShow="showData"></quickTool>
    <!-- 弹窗 -->
    <enterMask :showMask="open" :imgUrl="url" @onClose="open=false"></enterMask>
  </div>
</template>
<script>
import courseArrage from "@/components/courseArrage";
import feedBack from "@/components/feedBack";
import quickTool from "@/components/quickTool";
import enterMask from "@/components/enterMask";
export default {
    data(){
        return{
					showData:false,
					open:false,
					url:'',
					feedBack: [
						{
							avatar: "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/readFeedback2.png", name: "张泷月", grade: "二年级", 
							desc: "孩子特别喜欢这门课，每天都一起打卡，老师也能激发孩子的阅读兴趣，以前她很讨厌早读，现在也喜欢上了。"
						},
						{
							avatar: "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/readFeedback3.png", name: "喜得宝", grade: "二年级", 
							desc: "孩子很喜欢PK功能，以前都不爱看书不爱写字的，现在每天都看看有没有挑战他的伙伴。"
						},
						{
							avatar: "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/readFeedback4.png", name: "杰杰", grade: "二年级", 
							desc: "河小象的课我从寒假开始接触，一直到现在，我能感觉到他们的用心，不知不觉已经好几本了，希望以后能越来越好！"
						},
						{
							avatar: "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/readFeedback5.png", name: "小火", grade: "二年级", 
							desc: "孩子现在回家就可以用iPad上了，都不需要等我回家，方便好多，河小象真是好为我们家长考虑。"
						},
						{
							avatar: "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/readFeedback1.png", name: "王诺彦", grade: "二年级", 
							desc: "因为学校组织的硬笔书法比赛，有缘认识了河小象团队，学写字、古诗词、轻作文，收获满满。所有老师都非常热情，谢谢你们！"
						},
						{
							avatar: "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/readFeedback7.png", name: "花舞", grade: "二年级", 
							desc: "之前我家孩子看书很少，写完作业就想去玩，所以给他报了阅读课，她现在特别期待上课，因为有答题和朗诵环节。。"
						},
						{
							avatar: "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/readFeedback10.png", name: "优优", grade: "二年级", 
							desc: "我家宝贝每天早上起床第一件事就是打开河小象学习，下午幼儿园放学就上交作业，已经养成了好习惯！"
						},
						{
							avatar: "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/readFeedback9.png", name: "菲可", grade: "二年级", 
							desc: "孩子对故事情节很感兴趣，自己主动看后续的章节，学习下来词汇也积累了不少，期待学完后的效果。"
						},
						{
							avatar: "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/readFeedback1.png", name: "王诺彦", grade: "二年级", 
							desc: "孩子很喜欢这个课程，能够自己主动往后看了，而且动画的形式孩子很喜欢，可以培养阅读的兴趣。"
						},
						{
							avatar: "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/readFeedback2.png", name: "张泷月", grade: "二年级", 
							desc: "孩子特别喜欢这门课，每天都一起打卡，老师也能激发孩子的阅读兴趣，以前她很讨厌早读，现在也喜欢上了。"
						},
					]
        }
    },
    components:{
        courseArrage,
        feedBack,
        quickTool,
        enterMask
    },
		watch: {
			$route(to, from) {
				this.open = false;
			}
		},
		methods: {
			openMask(flag) {
				if (flag === 1) {
					this.open = true;
					this.url =
						"https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/enter-read.png";
				}
			}
		}
};
</script>
<style lang="scss" scoped>
.read-page {
  .read-bg {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    max-width: 1920px;
    max-height: 640px;
    margin: 0 auto;
    height: 640px;
    background: url(https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/read.png)
      no-repeat;
    background-size: 100% 640px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    .banner-head {
      text-align: left;
      padding-left: 15px;
      h1 {
        font-size: 54px;
        font-weight: bold;
        color: #ffffff;
      }
      .banner-tip {
        font-size: 32px;
        color: #fff;
        margin-top: 14px;
        position: relative;
        margin-bottom: 64px;
      }
      .banner-tip::after {
        content: "";
        width: 90px;
        height: 4px;
        border-radius: 2px;
        background-color: #fff;
        position: absolute;
        left: 0;
        bottom: -25px;
      }
    }
    .read-icon {
      height: 375px;
      width: 637px;
    }
    .banner-list {
      display: flex;
      color: #fff;
      align-items: center;
    }
    .banner-list p {
      line-height: 40px;
      font-size: 18px;
    }
    .banner-list img {
      object-fit: contain;
      margin-right: 10px;
      height: 18px;
    }
    .read-buy {
      width: 240px;
      height: 60px;
      background-color: #fdb758;
      border-radius: 30px;
      color: #fff;
      font-size: 26px;
      font-weight: bold;
      border: 0;
      cursor: pointer;
      outline: none;
      margin-top: 72px;
    }
  }
  .noLine-header {
    font-size: 50px;
    color: #444144;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold;
    position: relative;
    span {
      color: #8558c9;
    }
  }
  .read-header {
    font-size: 50px;
    color: #444144;
    font-weight: bold;
    font-family: MicrosoftYaHei-Bold;
    position: relative;
    margin-top: 55px;
    span {
      color: #8558c9;
    }
    &:after {
      position: absolute;
      content: "";
      width: 60px;
      height: 10px;
      border-radius: 5px;
      background-color: #8558c9;
      bottom: -33px;
      left: 50%;
      margin-left: -30px;
    }
  }
  .read-brief {
    margin: 74px auto 0;
    color: #444144;
    font-size: 22px;
    line-height: 38px;
    font-family: MicrosoftYaHei;
    width: 1120px;
    text-align: left;
    padding: 0 30px;
    letter-spacing: 1px;
    text-indent: 2em;
    box-sizing: border-box;
  }
  .brief-list {
    margin: 68px 0 86px;
    padding: 0 20px;
    .brief-box {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      max-width: 1120px;
      padding: 0 20px;
      box-sizing: border-box;
      margin: 0 auto;
      & > :first-child {
        margin-right: 128px;
      }
      & > .img-second {
        margin: 0 151px 0 53px;
      }
      &:not(:last-child) {
        margin-bottom: 70px;
      }
      .brief-left {
        color: #444144;
        width: 543px;
        text-align: left;
        .brief-header {
          font-size: 36px;
          font-weight: bold;
        }
        .brief-content {
          font-size: 20px;
          line-height: 40px;
          text-align: left;
          margin-top: 27px;
        }
      }
      .brief-right {
        width: auto;
        object-fit: cover;
        height: 310px;
      }
    }
  }
  .arrange {
    background-color: #f6f6f9;
    padding: 80px 0 89px;
  }
  .spcial {
    padding: 86px 0 0;
    .spcial-list {
      display: flex;
      margin: 70px auto 0;
      width: 100%;
      max-width: 1120px;
      flex-wrap: wrap;
      justify-content: space-between;
      .spcial-div {
        width: 33%;
        margin-bottom: 50px;
        // &:not(:nth-child(3n + 3)) {
        //   margin-right: 190px;
        // }
        .spcial-img {
          border-radius: 50%;
          width: 218px;
          height: 218px;
          object-fit: cover;
        }
        .spcial-title {
          color: #020202;
          font-size: 36px;
          margin: 20px 0 30px;
        }
        .spcial-tip {
          padding: 0 50px;
          color: #888888;
          font-size: 16px;
          line-height: 36px;
        }
      }
    }
  }
  .class-process {
    background-color: #f6f6f9;
    img{
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 1120px;
    }
  }
  .class-promise {
    background-color: #fff;
    img{
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 1120px;
    }
  }
}
</style>
