<template>
<div class="course-arrage">
     <ul class="course-tab">
          <li @click="cur=0" :class="{active:cur==0}" >
            <div class="tab-name">低段</div>
            <div class="tab-tip">适合一、二年级</div>
          </li>
          <li @click="cur=1" :class="{active:cur==1}">
            <div class="tab-name">中高段</div>
            <div class="tab-tip">适合三、四、五年级</div>
          </li>
     </ul>
     <div class="tab-content">
          <div class="list-header">
            <div class="classic">经典名著</div>
            <div class="knowledge">本周知识点</div>
            <div class="power">落实综合实力</div>
          </div>
           <transition name="slide-fade">
            <div v-show="cur==0">
                <div class="list-body">
                        <div class="list-box" v-for="(item,index) in primaryCourse" :key="index" v-show="isOpen||index<max">
                        <div class="classic">
                            <p>{{item.classic}}</p>
                        </div>
                        <div class="knowledge">
                            <p v-for="(it,inx) in item.knowledge" :key="inx">{{it}}</p>
                        </div>
                        <div class="power">
                            <p v-for="(it,inx) in item.power" :key="inx">{{it}}</p>
                        </div>
                </div>  
                        
                </div>
            </div>
           </transition>
           <transition name="slide-fade">
            <div v-show="cur==1">
                 <div class="list-body">
                <div class="list-box" v-for="(item,index) in midCourse" :key="index" v-show="isOpen||index<max">
                        <div class="classic">
                            <p>{{item.classic}}</p>
                        </div>
                        <div class="knowledge">
                            <p v-for="(it,inx) in item.knowledge" :key="inx">{{it}}</p>
                        </div>
                        <div class="power">
                            <p v-for="(it,inx) in item.power" :key="inx">{{it}}</p>
                        </div>
                </div>                
              </div>
            </div>
           </transition>
            <button class="look-all" v-show=" midCourse.length>max" @click="isOpen=!isOpen"><span>{{!isOpen?'点击查看全部':'收起'}}</span></button>
            <!-- <button class="look-all">收起</button> -->
     </div>
</div>   
</template>
<script>
export default {
data(){
    return{
       cur:0,
        max:3,
        isOpen:false,
        primaryCourse:[
            {
            week:['第一周','(督学周)','培养感受力'],
           classic:'《木偶奇遇记》：做一个诚实的孩子',
           knowledge:['认识作者卡洛·科洛迪','学习先后顺序','科普活动：小小环保家','学习保护环境的重要性','了解了木偶戏的历史以及起源','遇到危险应对策略','对牛弹琴的典故来源','···· '],
           power:['大自然感受力','视觉提升','听觉提升','触觉提升','语言感受力','明辨是非能力']
           },
            {
            week:['第二周','（观察周）','培养观察力'],
           classic:'《洋葱头历险记》：勇敢洋葱头与坏蛋斗智斗勇',
           knowledge:['认识作者：罗大里','了解意大利的岛湖风光','了解国际安徒生奖','科普军棋的玩法','学习ABCC成语：威风凛凛','了解电影《狗狗的疯狂假期》','活动：给洋葱头写信','······ '],
           power:['美感提升','勇于表达','知识面扩展','语感提升','理解力提升','学会提问','学会举一反三']
           },
        {
            week:['第三周','（习惯养成周）','培养表达力'],
           classic:'《爱丽丝漫游奇境》：用勇敢和智慧，面对困难 ',
           knowledge:['了解作者刘易斯卡罗尔','亲子活动：分角色朗读','生活小贴士：不能随意插嘴','科普玫瑰花的用途','了解黄粱一梦的故事','通过迷宫线路图了解故事情节','······ '],
           power:[' 观察更具目的性','学会用顺序词表达','阅读理解力提升','乐于表达']
           },
           {
            week:['第四周','（习惯加强周）','培养认知力'],
           classic:'《狐狸列那的故事》：一只聪明勇敢具有反抗精神的狐狸',
           knowledge:['故事创作背景','成语“争先恐后”','精彩段落赏析','圣诞节拓展知识','分析故事情节','表示神态的词语','近义词和反义词','“晏子使楚”的历史典故','我的感悟','......'],
           power:['记忆力','句段赏析能力','联想力','语言组织能力','善于与人交流']
           },
             {
            week:['第五周','（意识形成周）','培养专注力'],
           classic:'《吹牛大王历险记》：神奇的环球旅行',
           knowledge:['作者介绍','文学小知识','主要人物介绍','动词分析','趣味小知识：俄罗斯酒文化','学习拟声词','写作小提示：学会用拟声词描写动物','趣味小科普：英镑','小提示：保护大自然','动物小百科：狗熊','学习多音字：爪','和爸爸妈妈讨论：衣柜里的对话','文学小故事：狡兔三窟','小科普：立陶宛','学习排比的修辞手法'],
           power:['了解外国文化','提升写作技巧','了解计量单位','动物保护意识','语言表达能力']
           },
             {
            week:['第六周','（意识强化周）','培养思考力'],
           classic:'《吹牛大王历险记》：吹牛幻想不是美德',
           knowledge:['趣味大自然：沼泽的形成','学习词语：哆嗦','地理小知识：阿姆斯特丹','学习多音字：扎','学习词语：兴高采烈','地理小知识：北美','动物小百科：蓝鲸','学习比喻的修辞手法','地理小知识：维也纳','学习成语：溜之大吉','物理小知识：氢气','动手做一做：迷你热气球'],
           power:['感受大自然','了解自然规律','语句分析能力','了解地理知识','了解物理知识','动手能力']
           },
             {
            week:['第七周','（能力强化周）','培养创造力'],
           classic:'《为什么动物会有尾巴》：小动物的温情故事',
           knowledge:['作品介绍','主要角色介绍','我的小感悟：自信','指点迷津：感恩、切忌以貌取人','赏析好句子','点滴生词积累','课外拓展：乌鸦反哺','细节描写','人物外貌特征分析','想一想：面对陌生人的邀请','趣味科普：山桃与丝柏、鹦鹉','我是小演员：分角色朗读','含有动词的句子赏析','多音字：“血“、”泊”'],
           power:['肯定个人价值','明辨是非的能力','熟悉动物世界','联想力','增强安全意识','口语朗读能力','理解力']
           },
             {
            week:['第八周','（能力提升周）','七大能力综合巩固'],
           classic:'《为什么动物会有尾巴》：我们能从它们身上学会什么？',
           knowledge:['比喻的修辞手法','亲属名词：姑妈','动作描写','我的小感悟：舍己为人的牺牲精神','神态描写','头脑风暴：拯救阿尔封斯计划','趣科普：未雨绸缪的鼹鼠','词汇积累','好句赏析：角色心理描写','课外扩展：天鹅绒','老猫的形象解读','指点迷津：友谊多可贵','篇章主题：珍惜当下','角色外貌描写','植物知识拓展：枞树、染料木花、风铃草花、洋地黄花','分析有动词的句子'],
           power:['分析判断能力','段落品鉴能力','独立思考能力','感知能力','语言表达能力','了解植物的生长习性','语言表达能力']
           }
        ],
       midCourse:[
           {
            week:['第一周','(督学周)','培养感受力'],
           classic:'《鲁滨逊漂流记》：荒岛求生，敢于冒险',
           knowledge:['作者介绍','小说文本的叙述人称','航海家与重要的航海日期','鲁滨逊的原型','反问的修辞方式','投桃报李的故事','野外生存常识','赤道的地理知识','生字词的讲解','动词的用法','石臼是什么','面包是怎样发明的','人物分析','罗盘的小知识','·····'],
           power:['句子分析力','知错就改','知恩图报','字词认知力','动手实践能力','超强的想象力','野外求生能力','总结与概括能力','语言表达能力']
           },
            {
            week:['第二周','（观察周）','培养观察力'],
           classic:'《绿野仙踪》：“奥兹国”之旅',
           knowledge:['龙卷风的相关知识','作者介绍','比喻的修辞手法','理智与情感的关系','词语的积累','ABB词语的使用方法','罂粟花和毒蘑菇的科普','近义词的学习','西雅图的趣味科普','精彩段落的赏析','“奴隶”一词的相关科普','氢气球与热气球的原理','英尺与米的换算方式','天津瓷房子','会“动”的植物','己所不欲勿施于人','·····'],
           power:['提高表达能力','提高自身素养','事物认知能力','辨别能力','自我信心','动手实践能力','学习物理知识','地域的文化差别','提高文化常识','数学转换能力']
           },
        {
            week:['第三周','（习惯养成周）','培养表达力'],
           classic:'《时代广场的蟋蟀》：昆虫界的音乐奇才',
           knowledge:['二战期间的美国社会','耶鲁大学','介绍作者','纽伯瑞儿童文学奖','柴可夫斯基','了解大都市——纽约','1950s表达的含义','拟人的修辞手法','环境描写的句子','比喻的修辞手法','词语：呢喃','退一步海阔天空','科普眼里的感应小细胞','英国的风土人情','······'],
           power:['感受、分析历史的能力','提升文学素养','西方古典音乐鉴赏能力','语感','词语辨别力','人物形象分析能力','生物知识','学会看地图','了解民俗风情','学习乐理知识']
           },
           {
            week:['第四周','（习惯加强周）','培养认知力'],
           classic:'《时代广场的蟋蟀》：友情无价',
           knowledge:['美食小知识：燕窝、鱼翅','动作描写','货币科普：美元','多音字：括','近义词与反义词辨析','生字：喷嚏、颏','总统小知识：托马斯·杰斐逊','历史知识拓展：美国独立宣言','成语学习：逃之夭夭','动作和神态描写','比喻的修辞手法','我的小感悟：角色形象剖析','细节描写','艺术欣赏：陶瓷','趣地理：”瓷都“景德镇','外貌描写和服饰描写','筷子与”箸“','ABAC式成语','书上的好句子：侧面描写','音乐小知识：室内乐、华尔兹、伦巴','拟人的修辞手法','AABC式成语','锦囊妙计：写作技巧点拨','......'],
           power:['增加生活常识','提升文化素养','词汇理解能力','段落赏析能力','自主学习能力','审美品鉴能力']
           },
             {
            week:['第五周','（意识形成周）','培养专注力'],
           classic:'《柳林风声》：有难同当真朋友',
           knowledge:['作者简介','趣学校：爱丁堡大学','趣科普：掸子','精彩小句子：动态描写、反衬','趣动物：河鼠','我的小感悟：动物保护','成语积累','趣传说：嫦娥奔月','写作小技巧','比喻的修辞手法','自然常识：冬眠、迁徙','趣天文：公转','生词：挥霍','趣诗词：《赠花卿》','法律小知识：越狱','典故：孤注一掷','思考题：司机的做法','ABCC式成语','课程回顾','......'],
           power:['熟悉动物习性','提升写作能力','诗词鉴赏能力','了解生活常识','逻辑思维能力','反思总结能力']
           },
             {
            week:['第六周','（意识强化周）','培养思考力'],
           classic:'《格列佛游记》：充满奇幻色彩的冒险之旅',
           knowledge:['作者介绍','学习词语：萧条','趣味科普：七大洲八大洋','制作小船','科普：军衔','趣科普：京城','小知识：军舰','小知识：等级制度','小知识：麦田怪圈','学习成语：心乱如麻','了解伦敦金融城','学习成语：趾高气昂','小知识：全球著名建筑','学习成语：遍体鳞伤','趣科普：骆驼','科普：承德避暑山庄','活动：制作简历'],
           power:['了解地理知识','词语积累','散发性思维','提问力','快速思考能力','专注力','经济学思维']
           },
             {
            week:['第七周','（能力强化周）','培养创造力'],
           classic:'《格列佛游记》：值得学习的冒险精神',
           knowledge:['趣科普：巴比伦空中花园','学习词语：精神涣散、惶恐不安','学习物理知识：磁极','感受艺术建筑','做实验：种豆芽','学习成语：瞠目结舌','健康养生知识','学习成语：怨天尤人','趣科普：樱花','学习词语：俘虏、改邪归正','科普：汗血马','趣科普：野人','学习成语：截然不同','活动：织毛衣','趣科普：童话王国丹麦','小知识：钻石','活动：二十四节气歌','学习成语：悲痛欲绝'],
           power:['了解园林知识','艺术美感','了解历史','科学观察力','动手能力','随机应变能力','自控力','自信','创造性思维','独立自主意识']
           },
             {
            week:['第八周','（能力提升周）','七大能力综合巩固'],
           classic:'《海底两万里》：神秘莫测的海底探险',
           knowledge:['名家介绍','造句：庞然大物','词语积累','精读好句：比喻的修辞手法','生字：潜、曦','活动：补充海底档案馆','人物形象塑造','地理小知识：太平洋、印度洋、大西洋','文学小知识：《荷马史诗》','夸张的修辞手法','神态描写和动作描写','对餐厅的环境描写','趣自然：砗磲、海藻、角鲨','活动：写日记','外貌描写','ABCC式成语','疑问句的作用','我的小感悟：动物保护','多音字：混','ABB式词语'],
           power:['词汇实际运用能力','分类整理能力','综合素质能力','理解力','观察力','专注力']
           }
       ]
    }
},
    methods: {
    }   
}
</script>

<style lang="scss" scoped>
.course-arrage{
    padding: 0 20px;
    width: 1120px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    .course-tab{
        list-style: none;
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 47px 0 40px;
        padding: 0;
        li{
            width: 530px;
            height: 110px;
            color: #FF8F63;
            border-radius: 10px;
            box-sizing: border-box;
            border:2px solid #FF8F63;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &:hover{
                background-color: #FFEDE6;
            }
            .tab-name{
                font-size: 32px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .tab-tip{
               font-size: 14px;
            }
            &.active{
                border: 0;
                background-color: #FF8F63;
                color: #fff;
            }
        }
    }
    .tab-content{
        width: 100%;
        color: #fff;
        .list-header{
            display: flex;
            div{
                background-color: #FFB463;
                font-size: 20px;
                font-weight: bold;
                border-radius: 10px;
                min-height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 8px;
                &:not(:last-child){
                    margin-right: 8px;
                }
            }
        }
        .schedule{
            width: 500px;
        }
        .knowledge{
            width: 600px;
            p{
                width: 100%;
                padding: 0px 26px;
                box-sizing: border-box;
            }
        }
        .power{
            width: 316px;
        }
        .list-body{
            width: 100%;
            .list-box{
                display: flex;
                 &:nth-child(8n+1){
                    div{
                         background-color: #FFBB3B;
                    }
                }
                 &:nth-child(8n+2){
                    div{
                         background-color: #FFBB3B;
                    }
                }
                  &:nth-child(8n+3){
                    div{
                         background-color: #30E0C4;
                    }
                }
                &:nth-child(8n+4){
                    div{
                         background-color: #30E0C4;
                    }
                }
                   &:nth-child(8n+5){
                    div{
                         background-color: #5FAEFF;
                    }
                }
                &:nth-child(8n+6){
                    div{
                         background-color: #5FAEFF;
                    }
                }
                   &:nth-child(8n+7){
                    div{
                         background-color: #6082E5;
                    }
                }
                &:nth-child(8n+8){
                    div{
                         background-color: #6082E5;
                    }
                }
                div{
                background-color: #FFBB3B;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 10px;
                color: #fff;
                font-size: 16px;
                line-height: 24px;
                box-sizing: border-box;
                padding: 12px 26px;
                &:not(:last-child){
                    margin-right: 8px;
                }
                }
               &:not(:last-child){
                   div{
                       margin-bottom: 8px;
                   }
               }
            }
        }
        .classic{
            width: 400px;
            justify-content: center;
            word-break:break-all;
            p{
                width: 254px;
                padding: 0px 26px;
                box-sizing: border-box;
            }
        }
        .look-all{
            width: 100%;
            height: 60px;
            background-color: #FF8F63;
            border-radius: 10px;
            color: #fff;
            cursor: pointer;
            border: 0;
            outline: 0;
            font-size: 20px;
            margin-top: 40px;
        }

    }
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

</style>
